"use client";

import { ReactNode } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import BalanceProvider from "@/app/context/BalanceProvider";
import UserProvider from "@/app/context/UserProvider";
import { ToastContainer } from "react-toastify";

export default function Provider({
  children,
}: {
  children: ReactNode;
}): ReactNode {
  return (
    <SkeletonTheme baseColor="#F5F5F5" highlightColor="#FAFAFA">
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar
        draggablePercent={40}
        closeOnClick
        pauseOnHover
      />
      <BalanceProvider>
        <UserProvider>{children}</UserProvider>
      </BalanceProvider>
    </SkeletonTheme>
  );
}
