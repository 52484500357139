"use client";

import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { useSession } from "next-auth/react";
import { useMutation, useQuery } from "react-query";
import {
  getUser,
  putProfileEdit,
  putProfilePictureDelete,
} from "@/sources/user/api";
import { User } from "@/types/user";
import useErrorHandling from "@/hooks/useErrorHandling";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import SuccessIcon from "@/public/icons/success.svg";
import { usePathname, useRouter } from "@/navigation";
import { useLocale } from "next-intl";

type DefaultProps = {
  user: User | undefined;
  isUserLoading: boolean;
  userRefetch: () => void;
  deleteProfilePicture: any;
  editProfile: any;
  isLoadingEditProfile: boolean;
  isLoadingDeleteProfilePicture: boolean;
};

const defaultValue: DefaultProps = {
  user: undefined,
  isUserLoading: false,
  userRefetch: () => {},
  editProfile: () => {},
  isLoadingEditProfile: false,
  deleteProfilePicture: () => {},
  isLoadingDeleteProfilePicture: false,
};

const UserContext = createContext(defaultValue);

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { data: session } = useSession();
  const pathname = usePathname();
  const { onError } = useErrorHandling();
  const locale = useLocale();
  const router = useRouter();

  const {
    data: user,
    isLoading: isUserLoading,
    refetch: userRefetch,
  } = useQuery(
    ["user"],
    () => {
      return session?.accessToken && getUser(session.accessToken);
    },
    { enabled: true, onError: (error: AxiosError) => onError(error) },
  );

  useEffect(() => {
    if (user) {
      if (user?.language.code === "de" && locale === "en") {
        // @ts-ignore
        router.replace(pathname, { locale: "de" });
      }
      if (user?.language.code === "en" && locale === "de") {
        // @ts-ignore
        router.replace(pathname, { locale: "en" });
      }
    }
  }, [user, locale]);

  const { mutate: editProfile, isLoading: isLoadingEditProfile } = useMutation(
    (body: { onModalClose?: any; message?: string; data: any }) =>
      putProfileEdit(body.data, session?.accessToken),
    {
      onSuccess: (data, variables) => {
        toast.success(
          <p className="font-semibold text-[16px]">{variables.message}</p>,
          {
            icon: <SuccessIcon className="h-[20px] w-[20px] text-green" />,
          },
        );
        variables.onModalClose && variables.onModalClose();
        userRefetch();
      },
      onError: (error: AxiosError) => onError(error),
    },
  );

  const {
    mutate: deleteProfilePicture,
    isLoading: isLoadingDeleteProfilePicture,
  } = useMutation(
    (body: { message?: string; data: any }) =>
      putProfilePictureDelete(body.data, session?.accessToken),
    {
      onSuccess: (data, variables) => {
        toast.success(
          <p className="font-semibold text-[16px]">{variables.message}</p>,
          {
            icon: <SuccessIcon className="h-[20px] w-[20px] text-green" />,
          },
        );
        userRefetch();
      },
      onError: (error: AxiosError) => onError(error),
    },
  );

  return (
    <UserContext.Provider
      value={{
        user,
        isUserLoading,
        userRefetch,
        editProfile,
        isLoadingEditProfile,
        deleteProfilePicture,
        isLoadingDeleteProfilePicture,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
