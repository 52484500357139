import { signOut } from "next-auth/react";
import { toast } from "react-toastify";
import ErrorIcon from "@/public/icons/error.svg";
import React from "react";
import { useTranslations } from "next-intl";
import { getErrorMessage } from "@/utils";

const useErrorHandling = () => {
  const t = useTranslations();
  const onError = (error: any) => {
    console.log(error);
    if (error?.response.status === 401) {
      signOut({
        callbackUrl: `/login?error=${
          error?.response.data.error ||
          t(getErrorMessage(error?.response.data.status.type))
        }`,
      });
    } else if (error?.response.status === 422) {
      toast.error(
        <p className="font-semibold text-[16px]">
          {t("Profile.incorrectPassword")}
        </p>,
        {
          icon: ErrorIcon,
        },
      );
    } else {
      toast.error(
        <p className="font-semibold text-[16px]">
          {error?.response.data.error ||
            error?.response.data.data.message ||
            t(getErrorMessage(error?.response.data.status.type))}
        </p>,
        {
          icon: ErrorIcon,
        },
      );
    }
  };
  return {
    onError,
  };
};

export default useErrorHandling;
