"use client";

import React, { FC, ReactNode, useEffect, useState } from "react";
import dynamic from "next/dynamic";
const Header = dynamic(() => import("@/components/Header/Header"));
const Footer = dynamic(() => import("@/components/Footer/Footer"));
import { twMerge } from "tailwind-merge";
const MobileNavigation = dynamic(
  () => import("@/components/MobileNavigation/MobileNavigation"),
);
const ConfirmEmail = dynamic(() => import("@/components/Header/ConfirmEmail"));
const NewUserModal = dynamic(() => import("@/components/modals/NewUserModal"));
import { useSession } from "next-auth/react";
import { useUserContext } from "@/app/context/UserProvider";
import { postRefreshToken } from "@/sources/user/api";
import useAppHeight from "@/hooks/useAppHeight";
import { usePathname, useRouter } from "@/navigation";

type Props = {
  children: ReactNode;
};

const Layout: FC<Props> = ({ children }) => {
  useAppHeight();
  const pathname = usePathname();
  const { user, isUserLoading } = useUserContext();
  const { data: session, update } = useSession();
  const router = useRouter();
  const [isNewUserModalShown, showNewUserModal] = useState(false);
  const isOutsidePathname =
    pathname.includes("/login") ||
    pathname.includes("/register") ||
    pathname.includes("/password/edit") ||
    pathname.includes("impressum") ||
    pathname.includes("/confirmation") ||
    pathname.includes("/why-rewards") ||
    pathname.includes("/spieletests") ||
    pathname.includes("/bezahlte-umfragen") ||
    pathname.includes("/how-does-rewards-work") ||
    ((!session || (session && session.provider !== "credentials")) &&
      pathname.includes("/"));
  const isTaskPathname =
    pathname.includes("/task") || pathname.includes("/multitask");
  const isBonusPathname = pathname.includes("/bonus");
  const withEmailBanner =
    !isOutsidePathname && user && user.is_email_verified && !user.confirmed;

  useEffect(() => {
    if (
      !isUserLoading &&
      !isOutsidePathname &&
      user &&
      !user.gender &&
      !user.registration_age
    ) {
      showNewUserModal(true);
    }
  }, [user, isOutsidePathname, isUserLoading]);

  useEffect(() => {
    if (pathname.includes("/news") || pathname.includes("/quest")) {
      router.push("/bonus");
    }
  }, [pathname]);

  useEffect(() => {
    if (session && session.provider === "credentials") {
      postRefreshToken(session?.accessToken);
      update();
    }
  }, []);

  return (
    <div
      key={isOutsidePathname ? "outside" : "inside"}
      className={twMerge(isOutsidePathname && "bg-white")}
    >
      {!isOutsidePathname && <Header />}
      {!isOutsidePathname && <MobileNavigation />}
      {isNewUserModalShown && (
        <NewUserModal
          isOpen={isNewUserModalShown}
          onClose={() => showNewUserModal(false)}
        />
      )}
      <div
        className={
          isOutsidePathname
            ? "min-h-[100vh]"
            : "min-h-[calc(100vh_-_57px)] flex flex-col items-stretch justify-stretch"
        }
      >
        <div
          className={twMerge(
            "flex flex-col grow",
            !isOutsidePathname &&
              !isTaskPathname &&
              !isBonusPathname &&
              "max-w-[1150px] px-[20px] overflow-hidden m-[0_auto] w-full shrink-0",
            !isOutsidePathname && "pt-[64px] md:pt-[79px]",
            withEmailBanner && "md:pt-[131px]",
          )}
        >
          {user && user.is_email_verified && !user.confirmed && (
            <ConfirmEmail
              className={twMerge(
                "block md:hidden w-[calc(100%+40px)] ml-[-20px]",
                isTaskPathname && "w-full ml-0",
              )}
            />
          )}
          {children}
        </div>
      </div>
      {!isOutsidePathname && <Footer />}
    </div>
  );
};

export default Layout;
